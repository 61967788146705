import { DeepPartial } from '@reduxjs/toolkit';
import Service from './service';
import $api from '../http';
import {
    IProfile,
    PartialAdditionalAddress,
} from '../../../entities/user/model/types';

class UserService extends Service {
    // получение информации о пользователе и договорах
    async getInfo() {
        return await $api.get('/user/info');
    }

    async edit(data: DeepPartial<IProfile>) {
        return await $api.patch('/user/profile', data);
    }

    async addAddress(data: PartialAdditionalAddress) {
        try {
            return await $api.post('/user/address', {
                ...data,
            });
        } catch (err: any) {
            throw new Error(err);
        }
    }

    async deleteAddress(id: number) {
        return await $api.delete(`/user/address/${id}`);
    }

    async getAddresses() {
        return await $api.get('/user/address');
    }

    async promisePayment(oper: string, uid: number) {
        return await $api.post('/user/promise_of_payment', {
            oper,
            uid,
        });
    }

    async joinContinuousPayments(oper: string, uid: number) {
        return await $api.post('/user/join_continuous_payments', {
            oper,
            uid,
        });
    }

    async bonusExchange(oper: string, uid: number, amount: number) {
        return await $api.post('/user/bonus_exchange', {
            oper,
            uid,
            amount,
        });
    }
    
    async addAdditionalUid(parentOper: string, parentUid: number) {
        return await $api.post('/user/addition_uid', {
            parentOper,
            parentUid,
        });
    }

    async delAdditionalUid(parentUid: number) {
        return await $api.delete(`/user/addition_uid/${parentUid}`);
    }

    async checkPromo(oper: string, uid: number, promocode: string) {
        return await $api.post('/user/check_promocode', {
            oper,
            uid,
            promocode,

        });
    }
}

export default new UserService();
